<script setup lang="ts">
import AppLayoutHeader from './AppLayoutHeader.vue';
import AppLayoutWebViewHeader from './AppLayoutWebViewHeader.vue';
import AppLayoutTabBar from './AppLayoutTabBar.vue';
import AppLayoutFooter from './AppLayoutFooter.vue';
import { IncomingMessageContainer } from '@glow/shared/features/chat';
import AskQuestionBlock from './AskQuestionBlock.vue';
import ChatButton from './ChatButton.vue';
import { useAuthModalStore } from '@glow/shared/features/auth';
import { useAppLayoutBlockVisibilityStore } from '@/stores/appLayoutBlockVisibility';
import AppLayoutAuthPopover from './AppLayoutAuthPopover.vue';
import { FadeTransition } from '@glow/shared/components/transitions';

const props = withDefaults(
  defineProps<{
    title?: string;
    isHeaderVisible?: boolean;
    contentExpandable?: boolean;
    withSidebar?: boolean;
    withFooter?: boolean;
    withGutter?: boolean;
    withChatButton?: boolean;
    internalHeader?: boolean;
  }>(),
  {
    title: undefined,
    isHeaderVisible: true,
    withSidebar: true,
    withFooter: true,
    withGutter: true,
    withChatButton: true,
  }
);

const { showAuthModal } = useAuthModalStore();
const blockVisibility = useAppLayoutBlockVisibilityStore();
const isLoggedIn = useIsLoggedIn();
const isMobileApp = useIsMobileApp();
const isHeaderVisible = useHideOnScroll({ startOffset: 75, threshold: 40 });
const isMobileAppHeader = toRef(
  () => isMobileApp.value && props.internalHeader
);
</script>

<template>
  <div
    id="layout"
    class="app-layout"
    :class="{
      'app-layout_with-sidebar': props.withSidebar,
      'app-layout_header-hidden': !isHeaderVisible,
    }"
  >
    <header class="app-layout__header">
      <AppLayoutWebViewHeader v-if="isMobileAppHeader">
        <template #title>
          {{ props.title }}
        </template>
      </AppLayoutWebViewHeader>
      <AppLayoutHeader v-else />
      <div class="app-layout__additional-header">
        <FadeTransition>
          <slot name="additional-header"></slot>
        </FadeTransition>
      </div>
    </header>

    <div
      class="container app-layout__container"
      :class="{ 'app-layout__container_no-gutter': !props.withGutter }"
    >
      <slot name="grid-prepend"></slot>
      <div class="app-layout__grid">
        <main>
          <slot />
        </main>
        <aside v-if="props.withSidebar" class="app-layout__sidebar">
          <AskQuestionBlock
            v-if="blockVisibility.isAskQuestionBlockVisible"
            @close="blockVisibility.isAskQuestionBlockVisible = false"
          />
          <slot name="sidebar" />
        </aside>
      </div>
    </div>

    <footer v-if="props.withFooter && !isMobileApp" class="app-layout__footer">
      <AppLayoutFooter class="container" />
    </footer>

    <AppToastContainer class="app-layout__toast-container" />
    <IncomingMessageContainer class="app-layout__message-container" />

    <ChatButton
      v-if="isLoggedIn && props.withChatButton"
      class="app-layout__chat-button shadow d-none d-md-block"
    />

    <template v-if="!isLoggedIn">
      <div class="app-layout__auth-button d-md-none">
        <AppButton fluid @click="showAuthModal">
          Войти или зарегистрироваться
        </AppButton>
      </div>
      <div
        v-if="blockVisibility.isAuthPopoverVisible"
        class="app-layout__auth-popover d-none d-md-flex shadow"
      >
        <AppLayoutAuthPopover
          @close="blockVisibility.isAuthPopoverVisible = false"
        />
      </div>
    </template>

    <div class="app-layout__tab-bar d-md-none">
      <AppLayoutTabBar />
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-layout {
  --safe-area-inset-top: env(safe-area-inset-top, 0px);
  --safe-area-inset-bottom: env(safe-area-inset-bottom, 0px);

  --layout-header-height: 87px;
  --layout-auth-popover-offset: 0px;
  --layout-tab-bar-height: 0px;
  --content-vertical-padding: var(--app-spacing-8);
  --layout-padding-top: calc(
    var(--layout-header-height) + var(--content-vertical-padding) +
      var(--safe-area-inset-top)
  );
  --layout-fixed-padding-top: calc(
    var(--layout-header-height) + var(--safe-area-inset-top)
  );
  --layout-padding-bottom: calc(
    var(--layout-tab-bar-height) + var(--safe-area-inset-bottom)
  );
  --layout-safe-zone-height: calc(
    100vh -
      (
        var(--layout-padding-top) + var(--layout-padding-bottom) +
          var(--content-vertical-padding)
      )
  );

  @include media-breakpoint-down(lg) {
    --layout-header-height: 118px;
    --content-vertical-padding: var(--app-spacing-6);
  }

  @include media-breakpoint-down(md) {
    --content-vertical-padding: var(--app-spacing-4);
    --layout-header-height: 99px;
    --layout-tab-bar-height: 54px;
  }

  padding-top: var(--layout-padding-top);
  padding-bottom: var(--layout-padding-bottom);
  background: var(--app-color-white-secondary);

  &__container {
    @include media-breakpoint-up(sm) {
      padding: 0;
    }

    &_no-gutter {
      padding: 0;
    }
  }

  &__header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
    background: var(--app-color-white);
    box-shadow: 0px 0px 16px 0px rgba(20, 20, 20, 0.16);
    transition: 0.35s ease;
    padding-top: var(--safe-area-inset-top);
  }

  &_header-hidden &__header {
    transform: translateY(calc(var(--layout-header-height) * -1));
  }

  &_header-hidden {
    --layout-fixed-padding-top: var(--safe-area-inset-top);
  }

  &__additional-header {
    &:not(:empty) {
      border-top: 1px solid var(--app-color-white-quaternary);
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__tab-bar {
    position: fixed;
    z-index: 400;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(var(--layout-tab-bar-height) + var(--safe-area-inset-bottom));
    box-shadow: 0px 0px 16px 0px rgba(20, 20, 20, 0.16);
    padding-top: var(--app-spacing-1);

    @include glass-effect(60px, 0.75);
  }

  &__toast-container {
    position: fixed;
    right: 44px;
    top: 120px;
    max-width: 350px;
    z-index: var(--app-zi-toast);

    @include media-breakpoint-down(md) {
      right: unset;
      top: var(--app-spacing-4);
      left: 0;
      max-width: unset;
      width: 100vw;
    }
  }

  &__message-container {
    position: fixed;
    right: 44px;
    bottom: 120px;
    width: 300px;
    z-index: 500;

    @include media-breakpoint-down(md) {
      right: unset;
      top: unset;
      bottom: 16px;
      left: 50%;
      max-width: 95vw;
      transform: translateX(-50%);
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 75px;
    padding-bottom: var(--content-vertical-padding);
  }

  &_with-sidebar &__grid {
    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 0.42fr;
    }
  }

  main {
    min-width: 0;
  }

  &__chat-button {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 100;
  }

  &__footer {
    background: var(--app-color-white);
    padding: 50px 0;
  }

  &__auth-button {
    position: fixed;
    z-index: var(--app-zi-fixed);
    left: 0;
    bottom: var(--layout-padding-bottom);
    width: 100vw;
    padding: var(--app-spacing-3);
  }

  &__auth-popover {
    @include glass-effect;

    z-index: var(--app-zi-fixed);
    position: fixed;
    top: calc(
      var(--layout-fixed-padding-top) + var(--layout-auth-popover-offset) +
        var(--app-spacing-3)
    );
    right: calc((100vw - 1320px) / 2);
    padding: var(--app-spacing-3);
    border-radius: var(--app-br-md);
    transition: var(--app-transition-md);
    max-width: 350px;
  }
}
</style>
